/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

 @font-face {
  font-family: "Universal-Sans-Display-330";
  src: local("Universal-Sans-Display-330"),
   url("./fonts/Universal-Sans-Display-330.ttf") format("truetype");
  font-weight: absolute;
  } 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.font-face-us {
  font-family: "Universal-Sans-Display-330";
 } */

@tailwind base;
@tailwind components;
@tailwind utilities;

  @font-face {
    font-family: "Universal-Sans-Display-330";
    src: local("Universal-Sans-Display-330"),
     url("./fonts/Universal-Sans-Display-330.ttf") format("truetype");
  }

  @layer base {
    html {
      font-family: "Universal-Sans-Display-330", system-ui, sans-serif !important;
    }
    .font-sans{
      font-family: "Universal-Sans-Display-330", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    }
  }
